import React, { useState, useCallback } from "react";

import { Button, Grid } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import { toast } from "react-toastify";
import PaystubsUpload from "./PaystubsUpload";

export const AddCollateralForm = ({
  setRequestState,
  setLoanErrors,
  paystubsFiles,
  existedPaystubs,
  closeForm,
}) => {
  const [paystubs, setPaystubs] = useState(paystubsFiles);
  const [payStubsError, setPaystubsError] = useState(false);

  const theme = useTheme();

  const handleUploadImage = useCallback(
    (e, type) => {
      const { files } = e.target;

      if (paystubs.length + files.length > 2) {
        return toast.info("You can upload up to 2 images");
      }
      let paystubsUploads = [];

      Object.values(files).forEach(async (file) => {
        if (paystubsUploads.length <= 2) {
          paystubsUploads.push({
            _id: Math.random().toString(),
            data: file,
            type,
          });
        } else {
          toast.info("You can upload up to 2 images");
        }
      });

      setPaystubs([...paystubs, ...paystubsUploads]);
      setPaystubsError(false);
    },
    [paystubs]
  );

  const handleDeleteImage = (imageID, existedImageURL) => {
    if (existedImageURL) {
      const updatedExistedPaystubs = existedPaystubs.filter(
        (image) => image.url !== existedImageURL
      );
      setRequestState((prevState) => ({
        ...prevState,
        collateral: {
          paystubs: updatedExistedPaystubs,
        },
      }));
    } else {
      const updatedPaystubs = paystubs.filter((image) => image._id !== imageID);
      setPaystubs(updatedPaystubs);
    }
  };

  const hahdleFormSubmit = (e) => {
    e.preventDefault();

    if (existedPaystubs.concat(paystubs).length !== 2) {
      setPaystubsError(true);
      return;
    }
    setRequestState((prevState) => ({
      ...prevState,
      collateral: {
        paystubs: existedPaystubs,
      },
      paystubsFiles: paystubs,
      isCollateralExist: true,
    }));
    setLoanErrors((prevState) => ({ ...prevState, paystubsFiles: false }));
    closeForm();
  };

  return (
    <Grid
      container
      sx={{
        pl: { xs: 2, md: 5 },
        pr: { xs: 2, md: 5 },
        flexDirection: "column",
        width: { xs: "90vw", sm: "70vw" },
      }}
      id="collateral-form"
    >
      <Grid
        container
        item
        sx={{
          flexDirection: "row",
          gap: "7px",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          width: "100%",
          border: "1px solid rgba(66, 103, 178,0.3)",
          boxShadow: "0px 1px 6px 0px #7C9AEA",
          borderRadius: "6px",
          padding: "1em",
        }}
      >
        {/* Paystubs Uploads */}
        <PaystubsUpload
          allPaystubs={[...paystubs, ...existedPaystubs]}
          handleUploadImage={handleUploadImage}
          handleDeleteImage={handleDeleteImage}
          mainColor={theme.palette.primary.main}
          warningColor={theme.palette.warning.main}
          error={payStubsError}
        />

        <Grid
          container
          sx={{
            justifyContent: "flex-end",
            gap: "0.5rem",
            mt: {
              xs: "1rem",
              md: 0,
            },
          }}
        >
          <Button variant="contained" color="secondary" onClick={closeForm}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={hahdleFormSubmit}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
