import React, { memo, useCallback, useState } from "react";

import {
  Paper,
  Grid,
  useMediaQuery,
  TextField,
  Modal,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { styled, useTheme } from "@mui/material/styles";
import { LoanCardButton } from "./LoanCardButton";

import { ClipLoader } from "react-spinners";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "rgba(255,255,255,0.6)",
    borderRadius: "10px",
  },
});
export const TermsModal = memo(
  ({
    open,
    closeModalHandler,
    termsTitle,
    termsText,
    termsSign,
    submitCallback,
    isSignInput = false,
    signInputTitle,
    actionTitle = "Confirm Request",
    icon,
    PaypalComponent,
    isEditing,
    disableSaveBtn,
    isLoading,
  }) => {
    const [checked, setCheck] = useState(false);
    const [signInputValue, setSignInputValue] = useState("");
    const theme = useTheme();
    const smallMediaQuery = theme.breakpoints.between(0, 756);
    const isSmallMediaQuery = useMediaQuery(smallMediaQuery);

    const handleCancelBtnClick = () => {
      setSignInputValue("");
      setCheck(false);
      closeModalHandler();
    };

    const handleLoanRequestActionBtnClick = useCallback(() => {
      if (isEditing) {
        submitCallback();
      } else {
        submitCallback(checked, toast);
      }
    }, [isEditing, submitCallback, checked]);

    return (
      <Modal
        sx={{ zIndex: 1300 }}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Paper
            className="fade-in-slow"
            sx={{
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              padding: "1em 1.5em",
              borderRadius: "8px",
              maxHeight: "100%",
              width: "fit-content",
              maxWidth: {
                xs: "90%",
                sm: "75%",
                md: "450px",
              },
              overflowY: "auto",
            }}
            elevation={isSmallMediaQuery ? 0 : 6}
          >
            {icon && icon}
            {termsTitle && (
              <Typography
                fontWeight={700}
                style={{ marginBottom: "1em" }}
                fontSize={"20px"}
              >
                {termsTitle}
              </Typography>
            )}
            {termsText}
            {isSignInput && (
              <>
                <p style={{ fontWeight: 700 }}>{signInputTitle}</p>
                <CssTextField
                  fullWidth
                  placeholder={signInputTitle}
                  value={signInputValue}
                  onChange={(e) => setSignInputValue(e.target.value)}
                />
              </>
            )}
            {!PaypalComponent && termsSign && (
              <FormControlLabel
                checked={checked}
                onChange={() => setCheck(!checked)}
                style={{ marginTop: "1em" }}
                label={
                  <Typography color="#727987" fontSize={"14px"}>
                    {termsSign}
                  </Typography>
                }
                control={
                  <Checkbox sx={{ color: "#DDDDDD", borderRadius: "1px" }} />
                }
              />
            )}
            {PaypalComponent && !isEditing && (
              <Grid sx={{ m: "25px auto 0 auto" }}>{PaypalComponent}</Grid>
            )}
            <Grid
              item
              xs={12}
              spacing={1}
              sx={{
                width: "100%",
                marginTop: "0.5em",
                justifyContent: {
                  xs: PaypalComponent && "center",
                  sm: "center",
                  md: "flex-end",
                },
                ml: {},
              }}
              container
            >
              <Grid
                item
                sx={{
                  height: "fit-content",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                <LoanCardButton
                  title="Cancel"
                  btnProps={{
                    color: "secondary",
                    variant: "contained",
                    disabled: isLoading,
                  }}
                  cb={handleCancelBtnClick}
                  styles={{
                    mr: 1,
                    mt: { xs: 1, sm: 0 },
                    fontSize: { xs: "16px", md: "14px" },
                    order: {
                      xs: PaypalComponent ? 2 : !disableSaveBtn ? 3 : 2,
                      sm: 1,
                    },
                    flexBasis: {
                      xs: PaypalComponent ? "auto" : "60%",
                      sm: "auto",
                    },
                  }}
                />

                {!PaypalComponent && (
                  <LoanCardButton
                    title={actionTitle}
                    btnProps={{
                      variant: "contained",
                      disabled: isLoading,
                    }}
                    cb={handleLoanRequestActionBtnClick}
                    styles={{
                      mr: 1,
                      fontSize: { xs: "16px", md: "14px" },
                      order: { xs: 1, sm: 3 },
                      flexBasis: { xs: "60%", sm: "auto" },
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </>
      </Modal>
    );
  }
);
