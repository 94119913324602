import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Grid } from "@mui/material";

import { updateLoan } from "../../../actions/loan";

import { EditLoanModal } from "./EditLoanModal";

import { toast } from "react-toastify";
import { LoanRequestForm } from "../components/LoanRequestForm";
import { PaypalPayments } from "../../../Components";

const Request = () => {
  const { state } = useLocation();

  const [requestState, setRequestState] = useState(state.loan);
  const [showEditModal, setShowEditModal] = useState(false);
  const [revise, setRevise] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getRequestFormData = useCallback(() => {
    const formData = new FormData();
    for (let key in requestState) {
      if (key === "collateral" && requestState[key]) {
        formData.append(key, JSON.stringify(requestState[key]));
      } else if (key === "paystubsFiles" && requestState[key]) {
        for (let image of requestState[key]) {
          formData.append(key, image.data);
        }
      } else {
        formData.append(key, requestState[key]);
      }
    }
    return formData;
  }, [requestState]);

  const handleCloseTermsModal = useCallback(() => {
    setShowEditModal(false);
    setRevise(null);
  }, [setShowEditModal]);

  const handleSubmitLoanEdit = useCallback(() => {
    const formData = getRequestFormData();

    dispatch({ type: "LOAD" });
    if (state) {
      formData.set("isLoanEditing", "false");
      dispatch(
        updateLoan(
          state.loanId,
          "edit",
          ({ data }) => {
            if (data.action === "surcharge") {
              setRevise(data);
              return;
            }
            if (data.id) {
              setRequestState(null);
              navigate("/dashboard/", {});
              toast.success("Loan request has been updated successfully!");
              setShowEditModal(false);
            }
          },
          formData
        )
      );
      return;
    }
  }, [state, navigate, getRequestFormData, dispatch]);

  const cancelLoanEditingHandler = useCallback(() => {
    dispatch(updateLoan(state.loanId, "edit", null, { isLoanEditing: false }));
    navigate("/dashboard");
  }, [dispatch, navigate, state.loanId]);

  useEffect(() => {
    return () => {
      state?.loanId && cancelLoanEditingHandler();
    };
  }, [state?.loanId, cancelLoanEditingHandler]);

  return (
    <Grid container justifyContent="center">
      {showEditModal && (
        <EditLoanModal
          open={showEditModal}
          closeModalHandler={handleCloseTermsModal}
          submitCallback={handleSubmitLoanEdit}
          revise={revise}
          PaypalComponent={
            revise && (
              <PaypalPayments
                cb={handleSubmitLoanEdit}
                payeerRole="borrower"
                revise={revise}
              />
            )
          }
        />
      )}
      <LoanRequestForm
        requestState={requestState}
        formTitle="Update Loan Request"
        cancelClickHandler={() => cancelLoanEditingHandler(state.loanId)}
        submitButtonTitle="Update Request"
        setRequestState={setRequestState}
        submitCallback={() => setShowEditModal(true)}
        isLoanEditing={true}
      />
    </Grid>
  );
};

export default Request;
