import React, { memo, useState } from "react";

import {
  Paper,
  Grid,
  useMediaQuery,
  TextField,
  Modal,
  Checkbox,
  FormControlLabel,
  Typography,
  Alert,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import { LoanCardButton } from "../../../Components";
import { terms } from "../../../constants";
import { getStyles } from "./RequestTermsStyles";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "rgba(255,255,255,0.6)",
    borderRadius: "10px",
  },
});

export const RequestTermsModal = memo(
  ({
    open,
    closeModalHandler,
    submitCallback,
    PaypalComponent,
    publishData,
    revise,
  }) => {
    const [checked, setCheck] = useState(false);
    const [signInputValue, setSignInputValue] = useState("");
    const theme = useTheme();
    const smallMediaQuery = theme.breakpoints.between(0, 756);
    const isSmallMediaQuery = useMediaQuery(smallMediaQuery);

    const { subscription, isPublishAllowed } = publishData;

    const styles = getStyles(PaypalComponent);

    const handleCancelBtnClick = () => {
      setSignInputValue("");
      setCheck(false);
      closeModalHandler();
    };

    let currentTerms;

    if (revise) {
      currentTerms = terms.reviseSubscription(revise);
    } else {
      currentTerms = terms.requestLoan;
    }

    return (
      <Modal
        sx={{ zIndex: 1300 }}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          className="request-terms-wrapper fade-in-slow"
          sx={styles.wrapper}
          elevation={isSmallMediaQuery ? 0 : 6}
        >
          {!isPublishAllowed && (
            <Alert severity="warning">
              Only one published request is allowed until your outstanding loan
              is repaid. You can save this request to the Dasboard.
            </Alert>
          )}
          <Typography
            fontWeight={700}
            sx={{ marginBottom: "1em", marginInline: "auto" }}
            fontSize={"20px"}
          >
            {currentTerms.title}
          </Typography>

          {PaypalComponent && !revise
            ? `For publishing loan requests you have to pay for monthly subscription - USD ${subscription?.price}. You still can save loan request to the Dashboard`
            : currentTerms.text}

          {!PaypalComponent && !revise && (
            <>
              <p style={{ fontWeight: 700 }}>
                {terms.requestLoan.signInputTitle}
              </p>
              <CssTextField
                fullWidth
                placeholder={terms.requestLoan.signInputTitle}
                value={signInputValue}
                onChange={(e) => setSignInputValue(e.target.value)}
              />

              <FormControlLabel
                checked={checked}
                onChange={() => setCheck(!checked)}
                style={{ marginTop: "1em" }}
                label={
                  <Typography color="#727987" fontSize={"14px"}>
                    {terms.requestLoan.signText}
                  </Typography>
                }
                control={
                  <Checkbox sx={{ color: "#DDDDDD", borderRadius: "1px" }} />
                }
              />
            </>
          )}
          {PaypalComponent && (
            <Grid sx={{ m: "25px auto -12px auto" }}>{PaypalComponent}</Grid>
          )}
          <Grid
            item
            className="buttons-container"
            xs={12}
            spacing={1}
            sx={styles.buttonsContainer}
            container
          >
            <Grid item sx={styles.buttonsWrapper}>
              <LoanCardButton
                title="Cancel"
                btnProps={{
                  color: "secondary",
                  variant: "contained",
                }}
                cb={handleCancelBtnClick}
                styles={styles.cancelButton}
              />

              <LoanCardButton
                title={"Confirm & Save"}
                styles={styles.saveButton}
                cb={() => submitCallback(checked && signInputValue, "saved")}
              />

              {!PaypalComponent && !revise && (
                <LoanCardButton
                  title={terms.requestLoan.actionTitle}
                  btnProps={{
                    variant: "contained",
                    disabled: !isPublishAllowed,
                  }}
                  cb={() =>
                    submitCallback(checked && signInputValue, "published")
                  }
                  styles={styles.publishButton}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    );
  }
);
