import axios from "axios";
import url from "./url";

const API = axios.create({ baseURL: `${url}/payments` });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers.Authorization = localStorage.getItem("token");
  }
  return req;
});

export const createPaypalSubscriprionAPI = (requestBody) =>
  API.post(`/paypal/create-paypal-subscription`, requestBody);
export const createPaypalOrderAPI = (amount) =>
  API.post(`/paypal/create-paypal-order`, amount);
export const capturePaypalOrderAPI = (data) => {
  return API.post(`/paypal/capture-paypal-order`, data);
};
