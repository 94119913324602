import React, { memo } from "react";

import { Paper, Grid, useMediaQuery, Modal, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { LoanCardButton } from "../../../Components";
import { terms } from "../../../constants";

import { getStyles } from "./EditLoanModalStyles";

export const EditLoanModal = memo(
  ({ open, closeModalHandler, submitCallback, revise, PaypalComponent }) => {
    const theme = useTheme();
    const smallMediaQuery = theme.breakpoints.between(0, 756);
    const isSmallMediaQuery = useMediaQuery(smallMediaQuery);

    const styles = getStyles();

    let currentTerms;

    if (revise) {
      currentTerms = terms.reviseSubscription(revise);
    } else {
      currentTerms = terms.updateLoan;
    }

    return (
      <Modal
        sx={{ zIndex: 1300 }}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          className="request-terms-wrapper fade-in-slow"
          sx={styles.wrapper}
          elevation={isSmallMediaQuery ? 0 : 6}
        >
          {terms.updateLoan.icon}
          <Typography
            fontWeight={700}
            style={{ marginBottom: "1em" }}
            fontSize={"20px"}
          >
            {currentTerms.title}
          </Typography>

          {currentTerms.text}

          <Grid
            item
            className="buttons-container"
            xs={12}
            spacing={1}
            sx={styles.buttonsContainer}
            container
          >
            <Grid item sx={styles.buttonsWrapper}>
              <LoanCardButton
                title="Cancel"
                btnProps={{
                  color: "secondary",
                  variant: "contained",
                }}
                cb={closeModalHandler}
                styles={styles.cancelButton}
              />
              {PaypalComponent && (
                <Grid sx={{ mt: 2, order: 2 }}>{PaypalComponent}</Grid>
              )}
              {!revise && (
                <LoanCardButton
                  title={terms.updateLoan.actionTitle}
                  btnProps={{
                    variant: "contained",
                  }}
                  cb={submitCallback}
                  styles={styles.publishButton}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    );
  }
);
