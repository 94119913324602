import React, { useCallback, useState } from "react";

import {
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";

import { AddCollateralForm, PickDateInput } from "../../../Components";
import { formValidator } from "../../../utils";
import { LoanRequestFormInput } from "../../../Components/LoanRequestFormInput";
export function LoanRequestForm({
  requestState,
  formTitle = "Loan Request",
  cancelClickHandler,
  submitButtonTitle,
  setRequestState,
  submitCallback,
  isLoanEditing,
}) {
  const [errors, setErrors] = useState({
    date: false,
    requestedAmount: false,
    paybackAmount: false,
    borrowerLocation: false,
    paystubsFiles: false,
  });

  const [showCollateralForm, setShowCollateralForm] = useState(false);

  const {
    collateral,
    paystubsFiles = [],
    isCollateralExist,
    date,
    borrowerLocation,
  } = requestState || {};

  const handleAddCollateralClick = () => setShowCollateralForm(true);
  const handleDeleteCollateralClick = () => {
    if (collateral) {
      setRequestState((prevState) => ({
        ...prevState,
        collateral: null,
        isCollateralExist: false,
        paystubsFiles: [],
      }));
    }
  };
  const handleInputChange = useCallback(
    (e) => {
      const { id, value } = e.target;

      setRequestState((prevState) => ({ ...prevState, [id]: value }));
      setErrors((prevState) => ({ ...prevState, [id]: false }));
    },
    [setRequestState]
  );

  const handleFormValidation = (e) => {
    e.preventDefault();

    const [isValid, rejectedInput] = formValidator(requestState, "loan");

    if (isValid) {
      if (
        (!isLoanEditing && !paystubsFiles.length) ||
        (isLoanEditing &&
          [...collateral?.paystubs, ...paystubsFiles].length !== 2)
      ) {
        setErrors((prevState) => ({ ...prevState, paystubsFiles: true }));
        return;
      }
      submitCallback();
    } else {
      setErrors((state) => ({ ...state, [rejectedInput]: true }));
    }
  };

  if (!requestState) return null;
  if (showCollateralForm) {
    return (
      <AddCollateralForm
        existedPaystubs={collateral?.paystubs || []}
        paystubsFiles={paystubsFiles || []}
        setRequestState={setRequestState}
        isCollateralExist={isCollateralExist}
        closeForm={() => {
          setShowCollateralForm(false);
        }}
        setLoanErrors={setErrors}
      />
    );
  }
  return (
    <Grid
      container
      component="form"
      noValidate
      onSubmit={handleFormValidation}
      item
      xs={11}
      justifyContent={"flex-start"}
      style={{
        marginBottom: "1rem",
        backgroundColor: "white",
        border: "1px solid rgba(66, 103, 178,0.3)",
        boxShadow: "0px 1px 6px 0px #7C9AEA",
        borderRadius: "6px",
        padding: "1rem",
        gap: "8px",
      }}
    >
      <Typography
        component="h1"
        variant="h5"
        sx={{ fontWeight: 800, fontSize: "25px" }}
      >
        {formTitle}
      </Typography>
      <Grid item xs={12}>
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontWeight: 500, fontSize: "20px" }}
        >
          Requested Amount<span style={{ color: "#d32f2f" }}>*</span>
        </Typography>
        <LoanRequestFormInput
          fullWidth
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={requestState.requestedAmount}
          placeholder="100"
          id="requestedAmount"
          error={errors.requestedAmount}
          helperText={
            errors.requestedAmount
              ? "Please enter amount. Request must be less than $1,000"
              : ""
          }
          onChange={(e) => {
            if (!isNaN(e.target.value)) handleInputChange(e);
          }}
          name="requestedAmount"
          autoComplete="requestedAmount"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontWeight: 500, fontSize: "20px" }}
        >
          Payback Amount<span style={{ color: "#d32f2f" }}>*</span>
        </Typography>
        <LoanRequestFormInput
          fullWidth
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={requestState.paybackAmount}
          placeholder="Tip to lender suggested"
          id="paybackAmount"
          error={errors.paybackAmount}
          helperText={
            errors.paybackAmount
              ? "Please enter amount, greater than or equal to requested amount"
              : ""
          }
          onChange={(e) => {
            if (!isNaN(e.target.value)) handleInputChange(e);
          }}
          name="paybackAmount"
          autoComplete="paybackAmount"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontWeight: 500, fontSize: "20px" }}
        >
          Payback Date<span style={{ color: "#d32f2f" }}>*</span>
        </Typography>
        <PickDateInput
          dateState={date ? new Date(date).toISOString()?.split("T")[0] : ""}
          error={errors.date}
          errorText={
            errors.date
              ? `Please enter date greater then ${new Date().toLocaleDateString()}`
              : ""
          }
          handleChangeState={handleInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontWeight: 500, fontSize: "20px" }}
        >
          Location<span style={{ color: "#d32f2f" }}>*</span>
        </Typography>
        <LoanRequestFormInput
          fullWidth
          required
          inputProps={{ maxLength: 20 }}
          value={borrowerLocation}
          placeholder="Where are you located?"
          id="borrowerLocation"
          error={errors.borrowerLocation}
          helperText={
            errors.borrowerLocation
              ? "Please enter your location min.length-3 chars"
              : ""
          }
          onChange={handleInputChange}
          name="borrowerLocation"
          autoComplete="borrowerLocation"
        />
      </Grid>
      {/* Paystubs */}
      <Grid item xs={12} id="collateral">
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontWeight: 500, fontSize: "20px" }}
        >
          Paystubs
          <span style={{ color: "#d32f2f" }}>*</span>
        </Typography>
        {/* Collateral options */}
        <Grid
          container
          sx={{
            alignItems: "center",
            gap: 1,
          }}
        >
          <Grid sx={{ width: "fit-content" }}>
            <Button
              variant={!isCollateralExist ? "outlined" : "contained"}
              color="primary"
              onClick={handleAddCollateralClick}
              sx={{ fontSize: "0.8rem", mt: "8px" }}
            >
              {/* Paystubs Only */}
              {!requestState.isCollateralExist && "Add Paystubs"}
              {requestState.isCollateralExist &&
                // !requestState.collateral.title &&
                "Edit Paystubs"}
            </Button>
            {errors.paystubsFiles && (
              <FormHelperText
                sx={{ ml: 1, color: "#d32f2f" }}
                error={errors.collateral}
              >
                Please add paystubs
              </FormHelperText>
            )}
            {!isLoanEditing && collateral && (
              <Button
                variant="contained"
                color="warning"
                onClick={handleDeleteCollateralClick}
                sx={{ ml: 1, fontSize: "0.8rem", mt: 1 }}
              >
                Delete
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        spacing={1}
        sx={{ mt: 1 }}
        container
        justifyContent={"flex-end"}
        xs={12}
      >
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={cancelClickHandler}
            style={{ textTransform: "capitalize", fontSize: "1rem" }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            style={{
              textTransform: "none",
              fontSize: "1rem",
            }}
          >
            {submitButtonTitle}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
