import { memo } from "react";

import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";

import {
  capturePaypalOrderAPI,
  createPaypalOrderAPI,
  createPaypalSubscriprionAPI,
} from "../../../api/payments";

export const PaypalPayments = memo(
  ({ tierID, payeerRole, qty, cb, revise }) => {
    const intent = revise ? "capture" : "subscription";

    const createSubscription = async (data) => {
      try {
        const response = await createPaypalSubscriprionAPI({
          qty,
          tierID,
          payeerRole,
        });
        return response.data.id;
      } catch (e) {
        toast.error("Something went wrong. Try again");
      }
    };

    const createOrder = async () => {
      const orderResponse = await createPaypalOrderAPI({
        amount: revise.surchargeAmount,
        billingDate: revise.billingDate,
        requestMax: revise.requiredSubscriptionPlan.max,
        subscriptionID: revise.subscriptionID,
      });
      return orderResponse.data.id;
    };
    const onApprove = async (order) => {
      try {
        const response = await capturePaypalOrderAPI({
          orderID: order.orderID,
          subscriptionID: order.subscriptionID,
          payeerRole,
          intent,
          billingDate: revise?.billingDate,
          amount: +revise?.surchargeAmount?.toFixed(2),
        });

        if (response.data.status === 200) {
          cb(
            "You payment was successfull.\nLoan request has been published",
            "published"
          );
        }
      } catch (e) {
        toast.error("Something went wrong. Try again");
      }
    };
    const paypalButtonProps = {
      ...(intent === "subscription"
        ? {
            createSubscription: (order, actions) =>
              createSubscription(order, actions),
          }
        : {
            createOrder,
          }),
    };
    return (
      <PayPalScriptProvider
        options={{
          ...(intent === "subscription" && { vault: true }),
          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
          intent,
        }}
      >
        <PayPalButtons
          style={{
            layout: "vertical",
            color: "blue",
            label: intent === "capture" ? "pay" : "subscribe",
            height: 35,
          }}
          {...paypalButtonProps}
          onApprove={(order, actions) => onApprove(order, actions)}
          fundingSource="paypal"
        />
      </PayPalScriptProvider>
    );
  }
);
