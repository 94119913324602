import { createChatAPI, getAllChatsAPI, getSingleChatAPI } from "../api/chats";

/*Redux action for the all chats fetching */
export const getAllChatsAction = (query) => async (dispatch) => {
  try {
    dispatch({ type: "CHATS_LOAD" });
    const { data } = await getAllChatsAPI(query);

    dispatch({
      type: "SET_CHATS_LIST",
      payload: { chats: data.initialChatsList },
    });
    return data;
  } catch (error) {
    return error;
  }
};
/*Redux action for the selected chat fetching */
export const getSingleChatAction = (chatID, fetchAfter) => async (dispatch) => {
  try {
    const {
      data: { singleChat },
    } = await getSingleChatAPI(chatID, fetchAfter);
    /*initial - fist time selecting chat (for one session) */
    dispatch({
      type: "UPDATE_SELECTED_CHAT",
      payload: {
        messages: singleChat.messages.reverse(),
        type: "initial",
        chatID: singleChat.id,
      },
    });
    return singleChat;
  } catch (error) {
    return error;
  }
};
/*Redux action for the new chat creating,  */
export const createChatAction = (usersID, callback) => async (dispatch) => {
  try {
    /*API call return: if subscription valid - created chat, if not - message "invalid"  */
    const {
      data: { singleChat, subscription },
    } = await createChatAPI(usersID);

    if (typeof callback === "function") {
      callback(singleChat, subscription);
    }
    return singleChat;
  } catch (error) {
    return error;
  }
};
