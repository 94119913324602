import { Grid, Typography } from "@mui/material";

import { ReactComponent as Warning } from "../src/assets/warning.svg";

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const terms = {
  requestLoan: {
    title: "LOAN AGREEMENT",
    text: (
      <Grid container>
        <Typography fontSize={"14px"}>
          This Loan <strong>Agreement</strong> ("<strong>Agreement</strong>") is
          made and entered into as of [Date], by and between:
        </Typography>
        <Typography fontSize={"15px"} sx={{ mt: "5px" }}>
          <strong>1.Loan Amount and Terms</strong>
        </Typography>
        <Typography fontSize={"14px"}>
          The Lender agrees to loan the Borrower the principal sum of $[Loan
          Amount] ("Loan Amount").
        </Typography>
        <Typography fontSize={"15px"} sx={{ mt: "5px" }}>
          <strong>2.Loan Disbursement</strong>
        </Typography>
        <Typography fontSize={"14px"}>
          The Loan Amount will be disbursed to the Borrower on [Disbursement
          Date].
        </Typography>
        <Typography fontSize={"15px"} sx={{ mt: "5px" }}>
          <strong>3.Repayment Terms</strong>
        </Typography>
        <Typography fontSize={"14px"}>
          The Borrower agrees to repay the Loan Amount in full on or before
          [Repayment Date]. The repayment will be made in a single lump sum
          payment.
        </Typography>
        <Typography fontSize={"15px"} sx={{ mt: "5px" }}>
          <strong>4. Fees</strong>
        </Typography>
        <Typography fontSize={"14px"}>
          Collection Fees: In the event of default and legal action, the
          Borrower agrees to pay all reasonable collection fees, attorney fees,
          and court costs incurred by the Lender in connection with the
          collection of the debt.
        </Typography>
        <Typography fontSize={"15px"} sx={{ mt: "5px" }}>
          <strong>5. Default</strong>
        </Typography>
        <Typography fontSize={"14px"}>
          If the Borrower fails to repay the Loan Amount, including any
          applicable fees, by the Repayment Date, the Borrower will be in
          default. In the event of default, the Lender may take any lawful
          action to collect the outstanding amount, including initiating legal
          proceedings.
        </Typography>
        <Typography fontSize={"15px"} sx={{ mt: "5px" }}>
          <strong>6. Prepayment</strong>
        </Typography>
        <Typography fontSize={"14px"}>
          The Borrower may prepay the Loan Amount in whole or in part at any
          time without penalty.
        </Typography>
        <Typography fontSize={"15px"} sx={{ mt: "5px" }}>
          <strong>7. Right to Assign </strong>
        </Typography>
        <Typography fontSize={"14px"}>
          The Lender retains the right to assign or transfer this{" "}
          <strong>Agreement</strong>, including any rights and obligations
          hereunder, to a third party without the Borrower’s consent. Upon such
          assignment, the assignee shall have all the rights of the Lender under
          this <strong>Agreement</strong>.
        </Typography>
        <Typography fontSize={"15px"} sx={{ mt: "5px" }}>
          <strong>8. Governing Law</strong>
        </Typography>
        <Typography fontSize={"14px"}>
          This <strong>Agreement</strong> shall be governed by and construed in
          accordance with the laws of the state in which the Borrower resides.
        </Typography>
        <Typography fontSize={"15px"} sx={{ mt: "5px" }}>
          <strong>9. Entire Agreement</strong>
        </Typography>
        <Typography fontSize={"14px"}>
          This <strong>Agreement</strong> constitutes the entire agreement
          between the parties and supersedes all prior negotiations,
          understandings, and agreements between the parties.
        </Typography>
        <Typography fontSize={"15px"} sx={{ mt: "5px" }}>
          <strong>10. Amendments</strong>
        </Typography>
        <Typography fontSize={"14px"}>
          Any amendments to this <strong>Agreement</strong> must be in writing
          and signed by both parties.
        </Typography>
        <Typography fontSize={"15px"} sx={{ mt: "5px" }}>
          <strong>11. Electronic Signatures</strong>
        </Typography>
        <Typography fontSize={"14px"}>
          This <strong>Agreement</strong> and any amendments hereto may be
          signed electronically, and such electronic signatures shall be deemed
          to be valid and binding upon the parties. 12. Signatures By signing
          below, the borrower and lender acknowledges and agree to the terms and
          conditions of this <strong>Agreement</strong>.
        </Typography>
        <Typography fontSize={"15px"} sx={{ mt: "5px" }}>
          <strong>12. Signatures</strong>
        </Typography>
        <Typography fontSize={"14px"}>
          By signing below, the borrower and lender acknowledges and agree to
          the terms and conditions of this <strong>Agreement</strong>.
        </Typography>
        <Typography fontSize={"14px"} sx={{ mt: 2 }}>
          <strong>IMPORTANT:</strong> If you do not get funded for your loan
          request in 24 hours, you will receive your money back!
        </Typography>
      </Grid>
    ),
    signText: `I understand that this contract is legally binding and
         a breach of this contact will warrant neccesary repercussions.`,
    isSignInput: true,
    signInputTitle: "Full Name",
    actionTitle: "Confirm & Publish",
  },
  grantLoan: {
    title: "Loan Grant Contract",
    text: (
      <Typography fontSize={"14px"}>
        I have contacted the borrower, have done my due diligence and wish to
        proceed. I understand that getloanr.com is not a mediator and I waive
        any rights to persecute getloanr.com or it's subsidiaries.
      </Typography>
    ),
    signText: `I understand that this contract is legally binding and
      a breach of this contact will warrant neccesary repercussions.`,
    isSignInput: null,
    signInputTitle: null,
  },
  handleGrantedLoan: (type, lenderUsername) => {
    return {
      text:
        type === "confirm"
          ? ` Have you received the funds from the lender? Please do not confirm if you
    have not received the funds.`
          : `Click Confirm to deny the loan grant request from @${lenderUsername}`,
      actionTitle: "Confirm",
    };
  },
  updateLoan: {
    text: "Confirm if you want to update your loan request",
    actionTitle: "Confirm & Update",
    icon: (
      <Warning
        width="50px"
        height="50px"
        style={{ margin: "0 auto", marginBottom: "10px" }}
      />
    ),
  },
  reviseSubscription: (revise) => ({
    title: "Update Subscription",
    text: (
      <>
        <Typography fontSize={"14px"}>
          Your current subscription level allows you to request up to - USD{" "}
          {revise.maxAmount}. You need to pay the difference in subscription
          tiers in order to continue with the increased loan request.
        </Typography>
        <Typography fontSize={"14px"} sx={{ mt: 1 }}>
          Required subscription:
          <span style={{ display: "block" }}>
            - Range of request you are seeking: USD{" "}
            {revise.requiredSubscriptionPlan.min}.00 - USD{" "}
            {revise.requiredSubscriptionPlan.max}.00;
          </span>
          <span style={{ width: "100%" }}>
            - Difference to pay to publish - USD{" "}
            {revise.surchargeAmount.toFixed(2)};
          </span>
        </Typography>
        <Typography fontSize={"14px"} sx={{ mt: 2 }}>
          <strong>IMPORTANT:</strong> You will <strong>be charged now</strong>{" "}
          for the required difference and your subscription plan will not
          change. Your next billing date will not change -
          {revise.billingDate.split("T")[0]}.
        </Typography>
      </>
    ),
  }),
};

export const loanModalText = {
  grant: "Loan has been sent to borrower for confirmation",
  repaid: "Loan Request has been repaid",
  unpaid: "Loan Request has been marked as not repaid",
  confirm: "You confirmed loan grant",
};

export const emailNotificationTypes = [{ id: "newLoan", title: "new loan" }];

export const adminRoutes = {
  admin: [
    {
      path: "",
      componentName: "Home",
      menuItem: {
        title: "Dashboard",
        to: "/admin/",
      },
    },
    {
      path: "announcements",
      componentName: "AnnouncementsPage",
      menuItem: {
        title: "Announcements",
        to: "/admin/announcements",
      },
    },
    {
      path: "loan-board",
      componentName: "Board",
      menuItem: {
        title: "LoanBoard",
        to: "/admin/loan-board?type=requested",
      },
    },
    {
      path: "users",
      componentName: "Users",
      menuItem: {
        title: "Users",
        to: "/admin/users",
      },
    },
    {
      path: "settings/*",
      componentName: "Settings",
    },
    {
      path: "/profile/:id",
      componentName: "ProfilePage",
      menuItem: {
        title: "Users",
        to: "/admin/users",
      },
    },
  ],
  "admin:ro": [
    {
      path: "",
      componentName: "Users",
    },
    {
      path: "settings/*",
      componentName: "Settings",
    },
  ],
};
