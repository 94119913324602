import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router";

import { Grid, Typography, Button } from "@mui/material";

import {
  TermsModal,
  LoanModal,
  ReviewForm,
  PickDateInput,
} from "../../../Components";
import { updateLoan } from "../../../actions/loan";
import { terms as termsList, loanModalText } from "../../../constants";
import { normalizeCollateral } from "../../../utils";

import moment from "moment";
import { PaypalPayments } from "../../../Components/Payments/Paypal/PaypalPayments";
import { toast } from "react-toastify";

const Single = () => {
  const [success, setSuccess] = useState(false);
  const [terms, setTerms] = useState(false);
  const [showPaypalComponent, setShowPaypalComponent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReviewFormOpen, setIsReviewFormOpen] = useState(false);
  const [newPaybackDate, setNewPaybackDate] = useState(null);

  const { type } = useParams();

  const {
    auth: { user },
    appSettings,
  } = useSelector((state) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { loan } = location.state;

  const { title, text, signText } = termsList.grantLoan;

  const paypalModalText = `For granting loan requests you have to pay for monthly subscription - USD ${appSettings?.monthlySubscription}.`;

  let currentActionBtnTitle = "Confirm";
  let currentModalSuccessText = loanModalText.confirm;
  let toastMessage = "Request has been sent successfully";
  switch (type) {
    case "grant":
      currentActionBtnTitle = "Grant Loan";
      currentModalSuccessText = loanModalText.grant;
      toastMessage =
        "Loan has been granted and sent to the borrower for confirmation";
      break;
    case "repaid":
      currentActionBtnTitle = "Repaid";
      currentModalSuccessText = loanModalText.repaid;
      toastMessage = "Loan has been repaid. Please leave a review!";
      break;
    case "unpaid":
      currentActionBtnTitle = "Not Repaid";
      currentModalSuccessText = loanModalText.unpaid;
      toastMessage =
        "Loan has been marked as not repaid. Please leave a review!";
      break;
    case "extend-date":
      toastMessage = "Request has been sent to the borrower for confirmation";
      break;
    case "confirm-extend-date":
      toastMessage = "Confirmation has been sent successfully";
      break;
    case "cancel-extend-date" || "confirm-extend-date":
      currentActionBtnTitle = "Send Request";

      break;

    default:
      break;
  }
  const currentCancelBtnPath =
    type === "grant" ? "/dashboard/loan-board" : "/dashboard/";

  let currentCollateral;
  if (loan.collateral) {
    currentCollateral = normalizeCollateral(loan);
  }
  const reviewReceiver =
    user?.id === loan.granted_by?.id ? loan.created_by : loan.granted_by;

  const getModalTitle = () => {
    if (type === "unpaid") return "a loan is not repaid?";
    if (type === "extend-date") return "extend a loan payback date?";
    if (type === "confirm-extend-date") return "confirm payback date extension";
    if (type === "cancel-extend-date") return "cancel payback date extension";
    return type + " a Loan?";
  };

  const changeLoanStatusHandler = () => {
    setIsLoading(true);
    dispatch(
      updateLoan(
        loan.id,
        type,
        (response) => {
          if (response.data?.status === "invalid") {
            setShowPaypalComponent(true);
            setTerms(true);
            setIsLoading(false);
            return;
          }

          if (response.data?.id) {
            if (
              type !== "grant" &&
              type !== "confirm" &&
              !type.includes("extend")
            ) {
              setIsLoading(false);
              toast(toastMessage);
              setIsReviewFormOpen(true);
            } else {
              setIsLoading(false);
              toast(toastMessage);
              navigate("/dashboard");
              return;
            }
          }
        },
        newPaybackDate && {
          paybackDateRequest: { date: newPaybackDate, status: "pending" },
        }
      )
    );
  };

  const termsSubmitCallback = useCallback(
    async (checked, toast) => {
      if (checked) {
        changeLoanStatusHandler();
      } else {
        toast.error("Please check the checkbox");
      }
    },
    [loan, user]
  );

  const cancelTermsModal = () => {
    setTerms(false);
    setShowPaypalComponent(false);
  };

  const currentActionBtnHandler =
    type === "grant" ? () => setTerms(true) : changeLoanStatusHandler;

  const handleChangePaybackDate = (e) => {
    const { value } = e.target;

    setNewPaybackDate(value);
  };

  if (!loan) return null;
  return (
    <Grid
      container
      sx={{
        mt: "1rem",
        width: { xs: "100%", sm: "600px" },
        margin: {
          xs: 0,
          sm: "0 auto",
        },
      }}
    >
      <LoanModal
        open={success}
        setOpen={setSuccess}
        text={currentModalSuccessText}
      />
      <TermsModal
        open={!!terms}
        closeModalHandler={cancelTermsModal}
        submitCallback={termsSubmitCallback}
        termsTitle={title}
        termsText={showPaypalComponent ? paypalModalText : text}
        termsSign={signText}
        disableSaveBtn={true}
        PaypalComponent={
          showPaypalComponent && (
            <PaypalPayments cb={changeLoanStatusHandler} payeerRole="lender" />
          )
        }
        isLoading={isLoading}
      />
      <Grid
        container
        item
        xs={12}
        spacing={2}
        sx={{
          margin: "0 0 1em 0",
          backgroundColor: "white",
          border: "1px solid rgba(66, 103, 178,0.3)",
          boxShadow: "0px 1px 6px 0px #7C9AEA",
          borderRadius: "6px",
          padding: "1em",
        }}
      >
        <Grid item xs={12}>
          <Typography
            fontWeight={700}
            fontSize={"28px"}
            sx={{
              textTransform: "capitalize",
              textAlign: { xs: "center", sm: "start" },
            }}
          >
            {getModalTitle()}
          </Typography>
        </Grid>

        <Grid
          item
          container
          xs={12}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography style={{ fontSize: "20px" }}>Loan Amount</Typography>
          </Grid>
          <Grid item xs={6} container justifyContent={"flex-end"}>
            <Typography style={{ fontSize: "20px" }}>
              ${loan.requestedAmount}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography style={{ fontSize: "20px" }}>
              {type.includes("extend") && "New "}Payback Date
            </Typography>
          </Grid>
          <Grid item xs={6} container justifyContent={"flex-end"}>
            {type === "extend-date" ? (
              <PickDateInput
                extendDate
                dateState={newPaybackDate || ""}
                handleChangeState={handleChangePaybackDate}
              />
            ) : (
              <Typography style={{ fontSize: "20px" }}>
                {moment(loan.paybackDateRequest?.date || loan.date).format(
                  "MM/DD/YYYY"
                )}
              </Typography>
            )}
          </Grid>
        </Grid>

        {!isReviewFormOpen ? (
          <Grid item spacing={1} container justifyContent={"flex-end"} xs={12}>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  fontSize: "1rem",
                  color: "black",
                }}
                onClick={() => navigate(currentCancelBtnPath)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontSize: "1rem",
                }}
                onClick={currentActionBtnHandler}
              >
                {currentActionBtnTitle}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <ReviewForm
            user={user}
            closeReviewForm={() => setIsReviewFormOpen(false)}
            reviewReceiver={reviewReceiver}
            loanID={loan.id}
            authorRole={loan.created_by.id === user.id ? "borrower" : "lender"}
            postReviewCallback={() => {
              setTerms(null);
              navigate("/dashboard");
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Single;
