import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { ReviewForm, LinkToProfile, LoanCardButton } from "../Components";
import moment from "moment";

export const LoanCard = ({
  loan,
  deleteLoanBtnClick,
  onGrantedLoanBtnClick,
  editLoanHandler,
  grantLoanHandler,
  isShort,
  styles,
  backLocation,
  target,
  handlePublishLoanRequest,
  handleLoanNotRepaid,
  postReviewCallback,
  handleOnMessageClick,
  handleExtendPaybackDate,
  handleRequestPaystubs,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [isReviewFormOpen, setIsReviewFormOpen] = useState(false);

  const isOwnLoan = loan.created_by?.id === user.id;

  let mainLoanStatus;
  switch (loan.status) {
    case "granted":
      mainLoanStatus = "IN PROGRESS";
      break;
    case "preconfirmed":
      mainLoanStatus = "IN PROGRESS";
      break;
    case "paid":
      mainLoanStatus = "COMPLETED";
      break;
    default:
      mainLoanStatus = "REQUEST";
  }

  const isLoanGranted = loan.granted_by;
  const isGrantingConfirmed = loan.isGrantingConfirmed;
  const isLoanRepaid = loan.isRepaid;
  const isReviewLeft = loan.reviews?.includes(user.id);
  const userIsLender = loan.granted_by?.id === user.id;
  const isLoanUnpaid = loan.status === "unpaid";
  const isLoanRefunded = loan.status === "refunded";
  const isDateChangeRequested = loan.paybackDateRequest?.status === "pending";

  const isLoanBoard = window.location.pathname.includes("loan-board");

  const navigate = useNavigate();
  const theme = useTheme();

  const reviewReceiver = isOwnLoan ? loan.granted_by : loan.created_by;

  return (
    <Grid
      id={loan.id}
      container
      sx={{
        flexDirection: "column",
        position: "relative",
        width: "600px",
        height: "fit-content",
        maxWidth: "100%",
        padding: "8px",
        backgroundColor: "white",
        border: "1px solid rgba(66, 103, 178,0.3)",
        boxShadow: loan?.granted_by
          ? "rgb(113 137 201) 0px 0px 10px 2px"
          : "0px 1px 6px 0px #7C9AEA",
        borderRadius: "6px",
        margin: "0 auto 8px auto",
        ...styles,
      }}
    >
      {!isShort && (
        <>
          <Grid container>
            {/* Borrower username */}
            <Grid container item sx={{ width: "fit-content" }}>
              <Typography sx={{ color: "#787C7E", fontSize: "12px" }}>
                Posted by{" "}
              </Typography>
              {!isOwnLoan ? (
                <LinkToProfile
                  user={loan.created_by}
                  backLocation={backLocation}
                  target={target}
                />
              ) : (
                <Typography fontSize="13px" sx={{ m: "0 2px" }}>
                  {"@" + loan.created_by?.username}
                </Typography>
              )}
              {/* Published Date */}
              <Typography sx={{ color: "#787C7E", fontSize: "12px" }}>
                {", " + moment.utc(loan.createdAt).format("MMM/DD/YY")}
              </Typography>
            </Grid>
            {isLoanRefunded && (
              <Grid
                sx={{
                  ml: "auto",
                  boxShadow: `0px 1px 5px 1px ${theme.palette.primary.main}`,
                  borderRadius: "8px",
                  p: "6px",
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Fee Refunded
                </Typography>
              </Grid>
            )}
          </Grid>
          {/* Fulfilled Badge */}
          {isGrantingConfirmed && isLoanBoard && (
            <Grid
              container
              item
              sx={{
                width: "fit-content",
                height: "fit-content",
                padding: "1px 5px",
                alignItems: "center",
                boxShadow: `0px 1px 5px 1px ${theme.palette.primary.main}`,
                margin: "5px auto 0 auto",
              }}
            >
              <Typography
                fontSize={"13px"}
                fontWeight={400}
                sx={{ marginRight: "2px" }}
              >
                Fulfilled Loan by
              </Typography>
              <LinkToProfile
                user={loan.granted_by}
                backLocation={backLocation}
                target={target}
              />
            </Grid>
          )}
        </>
      )}

      <Grid
        container
        sx={{
          width: "fit-content",
          mt: 1,
          cursor: isShort && "pointer",
        }}
        onClick={(e) => {
          if (e.target.parentNode.tagName !== "BUTTON" && isShort)
            navigate(`/dashboard/loan-board`, { state: { target: loan.id } });
        }}
      >
        {/* General Loan Status */}
        <Typography>
          <span
            style={{
              backgroundColor: "rgb(225 229 235)",
              borderRadius: "20px",
              padding: "2px 8px",
              color: "#000000",
              fontWeight: "600",
              marginRight: "3px",
              textTransform: "uppercase",
            }}
          >
            {/* Specific Loan Status */}
            {mainLoanStatus}
          </span>
          <span style={{ textTransform: "uppercase" }}>
            [{loan.status === "refunded" ? "fee refunded" : loan.status}]
          </span>
          (${loan.requestedAmount}) - (#{loan.borrowerLocation}), (
          <strong>Repay</strong> ${loan.paybackAmount} by{" "}
          {moment.utc(loan.date).format("MMM Do, YYYY")}){/* , ( */}
        </Typography>
      </Grid>
      {/* Action Buttons */}
      {!isLoanRefunded && (
        <Grid sx={{ m: "8px auto 0 auto" }}>
          {!isLoanBoard && isOwnLoan && !isLoanGranted && !isLoanRefunded && (
            <Grid item container sx={{ width: "fit-content", gap: "5px" }}>
              <LoanCardButton
                title="Delete"
                btnProps={{ color: "warning" }}
                cb={() => deleteLoanBtnClick(loan.id)}
              />

              <LoanCardButton title="Edit" cb={() => editLoanHandler(loan)} />
              {handlePublishLoanRequest && (
                <LoanCardButton
                  title="Publish"
                  cb={() =>
                    handlePublishLoanRequest(loan.id, loan.requestedAmount)
                  }
                />
              )}
            </Grid>
          )}
          {!isLoanBoard && isOwnLoan && isLoanGranted && (
            <Grid
              container
              item
              sx={{
                width: "fit-content",
                m: "3px auto 0 auto",
                gap: "5px",
                border: isDateChangeRequested && "1px solid #ccc",
                borderRadius: isDateChangeRequested && "8px",
                padding: isDateChangeRequested && { xs: "5px", sm: "7px" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!isGrantingConfirmed ? (
                <>
                  <LoanCardButton
                    title="Confirm"
                    cb={() => onGrantedLoanBtnClick({ loan, type: "confirm" })}
                  />
                  <LoanCardButton
                    title="Deny"
                    cb={() =>
                      onGrantedLoanBtnClick({
                        id: loan.id,
                        type: "deny-grant",
                        lenderUsername: loan.granted_by.username,
                      })
                    }
                    btnProps={{ color: "warning" }}
                  />
                </>
              ) : (
                isDateChangeRequested && (
                  <>
                    <ExtendDateInfoMessage
                      paybackDateRequest={loan.paybackDateRequest.date}
                    />
                    <LoanCardButton
                      title="Confirm"
                      cb={() =>
                        handleExtendPaybackDate(
                          "confirm-extend-date",
                          loan,
                          true
                        )
                      }
                    />
                    <LoanCardButton
                      btnProps={{ color: "warning" }}
                      title="Cancel"
                      cb={() =>
                        handleExtendPaybackDate(
                          "cancel-extend-date",
                          loan,
                          true
                        )
                      }
                    />
                  </>
                )
              )}
            </Grid>
          )}
          {!isOwnLoan && !isLoanGranted && (
            <Grid container sx={{ justifyContent: "center", gap: "5px" }}>
              {!isShort && (
                <LoanCardButton
                  btnProps={{ disabled: !loan.created_by }}
                  title="Message"
                  cb={() => handleOnMessageClick(loan.created_by.id)}
                />
              )}
              {grantLoanHandler && (
                <LoanCardButton
                  btnProps={{ disabled: !loan.created_by }}
                  title="Grant Loan"
                  cb={() => grantLoanHandler(loan)}
                />
              )}
              {!isShort && (
                <LoanCardButton
                  btnProps={{ disabled: !loan.created_by }}
                  title="Request Paystubs"
                  cb={() =>
                    handleRequestPaystubs("request", user, loan.created_by)
                  }
                />
              )}
            </Grid>
          )}
          {/* Repaid & Unpaid Buttons */}
          <Grid container sx={{ justifyContent: "center", mt: "3px" }}>
            {isGrantingConfirmed &&
              !isLoanBoard &&
              !isLoanRepaid &&
              !isLoanUnpaid &&
              userIsLender && (
                <>
                  {isDateChangeRequested && (
                    <Grid
                      sx={{
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        padding: { xs: "5px", sm: "7px" },
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ExtendDateInfoMessage
                        paybackDateRequest={loan.paybackDateRequest.date}
                        isLender
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    sx={{ width: "fit-content", gap: "5px", mt: "8px" }}
                  >
                    <LoanCardButton
                      title="Repaid"
                      cb={() =>
                        navigate(`/dashboard/loan-board/${loan.id}/repaid`, {
                          state: { loan },
                        })
                      }
                      centered
                    />

                    {moment(loan.date).isSameOrBefore(moment()) && !isShort && (
                      <LoanCardButton
                        title="Extend Payback"
                        cb={() =>
                          handleExtendPaybackDate("extend-date", loan, false)
                        }
                        centered
                      />
                    )}
                    <LoanCardButton
                      title="Not Repaid"
                      btnProps={{
                        color: "warning",
                      }}
                      cb={handleLoanNotRepaid}
                      centered
                    />
                  </Grid>
                </>
              )}
            {((isLoanRepaid && !isLoanBoard && !isReviewLeft) ||
              (loan.status === "unpaid" && userIsLender && !isReviewLeft)) && (
              <>
                {!isReviewFormOpen ? (
                  <LoanCardButton
                    title="Leave review"
                    cb={() => setIsReviewFormOpen(true)}
                    styles={{ m: "0 0 0 5px" }}
                  />
                ) : (
                  <ReviewForm
                    user={user}
                    loanID={loan.id}
                    authorRole={isOwnLoan ? "borrower" : "lender"}
                    closeReviewForm={() => setIsReviewFormOpen(false)}
                    reviewReceiver={reviewReceiver}
                    isOnLoanCard={true}
                    postReviewCallback={postReviewCallback}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const ExtendDateInfoMessage = ({ paybackDateRequest, isLender }) => {
  return (
    <Typography>
      New payback date:{" "}
      <strong>{moment.utc(paybackDateRequest).format("MMM Do, YYYY")}</strong>
      {isLender && "- waiting for Borrower confirmation"}
    </Typography>
  );
};
