import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";

import { checkPublishPermission, createLoans } from "../../../actions/loan";
import { PaypalPayments } from "../../../Components/Payments/Paypal/PaypalPayments";
import { RequestTermsModal } from "./RequestTermsModal";

import { toast } from "react-toastify";
import { LoanRequestForm } from "../components/LoanRequestForm";

const Request = () => {
  const {
    auth: { user },
  } = useSelector((state) => state);
  let initialState = useMemo(
    () => ({
      requestedAmount: "",
      paybackAmount: "",
      date: "",
      borrowerLocation: "",
      paystubsFiles: [],
      isCollateralExist: false,
      isLoanEditing: false,
    }),
    []
  );
  const [requestState, setRequestState] = useState(initialState);

  const [showRequestTerms, setShowRequestTerms] = useState(false);

  const [showPaypalComponent, setShowPaypalComponent] = useState(false);
  const [revise, setRevise] = useState(false);
  const [publishData, setPublishData] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCheckPublishPermision = (cb) => {
    dispatch(
      checkPublishPermission(user.id, requestState.requestedAmount, (data) => {
        setPublishData(data);
      })
    );
    setShowRequestTerms(true);
  };

  const getRequestFormData = useCallback(() => {
    const formData = new FormData();
    for (let key in requestState) {
      if (key === "collateral" && requestState[key]) {
        formData.append(key, JSON.stringify(requestState[key]));
      } else if (key === "paystubsFiles" && requestState[key]) {
        for (let image of requestState[key]) {
          formData.append(key, image.data);
        }
      } else {
        formData.append(key, requestState[key]);
      }
    }
    return formData;
  }, [requestState]);

  const handleCreateLoanResponse = useCallback((toastMessage, response) => {
    /*If it's saving or publishing with valid subscription */
    if (response?.status === "published" || response?.status === "saved") {
      setShowRequestTerms(false);
      setRequestState(initialState);
      setShowPaypalComponent(false);
      navigate("/dashboard");
      toast(toastMessage);
    } else if (
      response?.status === "valid" &&
      response?.action === "surcharge"
    ) {
      setRevise(response);
      setShowPaypalComponent(true);
    } else if (response?.status === "invalid") {
      setShowPaypalComponent(true);
    }
  }, []);

  const handleCreateLoan = useCallback(
    (toastMessage, status) => {
      const formData = getRequestFormData();
      formData.append("status", status);
      dispatch(
        createLoans(formData, (response) =>
          handleCreateLoanResponse(toastMessage, response)
        )
      );
    },
    [dispatch, getRequestFormData, handleCreateLoanResponse]
  );

  const submitLoanRequestTermsModal = useCallback(
    async (isTermsAccepted, publishedStatus) => {
      const toastMessage =
        publishedStatus === "saved"
          ? "Loan request has been saved to the dashboard"
          : "Loan request has been published";
      if (isTermsAccepted) {
        handleCreateLoan(toastMessage, publishedStatus);
      } else {
        toast.error("Please check the checkbox and fill out the input");
      }
    },
    [handleCreateLoan]
  );

  const handleCloseTermsModal = useCallback(() => {
    setShowPaypalComponent(false);
    setShowRequestTerms(false);
    setRevise(false);
  }, [setShowRequestTerms]);

  return (
    <Grid container justifyContent="center">
      {publishData && (
        <RequestTermsModal
          open={showRequestTerms}
          revise={revise}
          closeModalHandler={handleCloseTermsModal}
          submitCallback={submitLoanRequestTermsModal}
          PaypalComponent={
            showPaypalComponent && (
              <PaypalPayments
                cb={handleCreateLoan}
                qty={publishData.subscription.qty}
                tierID={publishData.subscription.id}
                payeerRole="borrower"
                revise={revise}
              />
            )
          }
          publishData={publishData}
        />
      )}
      <LoanRequestForm
        requestState={requestState}
        cancelClickHandler={() => navigate("/dashboard/loan-board")}
        submitButtonTitle="Next"
        setRequestState={setRequestState}
        submitCallback={handleCheckPublishPermision}
      />
    </Grid>
  );
};

export default Request;
